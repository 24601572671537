/* eslint "angular/ng_di":0 */

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name country.constant:countries
   *
   * @description
   *
   */
  angular
    .module('wbCountry')
    .constant('countries', [
      {name: 'country.AFG', alpha3: 'AFG'},
      {name: 'country.ALA', alpha3: 'ALA'},
      {name: 'country.ALB', alpha3: 'ALB'},
      {name: 'country.DZA', alpha3: 'DZA'},
      {name: 'country.UMI', alpha3: 'UMI'},
      {name: 'country.USA', alpha3: 'USA'},
      {name: 'country.ASM', alpha3: 'ASM'},
      {name: 'country.VIR', alpha3: 'VIR'},
      {name: 'country.AND', alpha3: 'AND'},
      {name: 'country.AGO', alpha3: 'AGO'},
      {name: 'country.AIA', alpha3: 'AIA'},
      {name: 'country.ATA', alpha3: 'ATA'},
      {name: 'country.ATG', alpha3: 'ATG'},
      {name: 'country.ARG', alpha3: 'ARG'},
      {name: 'country.ABW', alpha3: 'ABW'},
      {name: 'country.AUS', alpha3: 'AUS'},
      {name: 'country.AUT', alpha3: 'AUT'},
      {name: 'country.AZE', alpha3: 'AZE'},
      {name: 'country.BHS', alpha3: 'BHS'},
      {name: 'country.BHR', alpha3: 'BHR'},
      {name: 'country.BGD', alpha3: 'BGD'},
      {name: 'country.BRB', alpha3: 'BRB'},
      {name: 'country.BEL', alpha3: 'BEL'},
      {name: 'country.BLZ', alpha3: 'BLZ'},
      {name: 'country.BEN', alpha3: 'BEN'},
      {name: 'country.BMU', alpha3: 'BMU'},
      {name: 'country.BTN', alpha3: 'BTN'},
      {name: 'country.GNB', alpha3: 'GNB'},
      {name: 'country.BOL', alpha3: 'BOL'},
      {name: 'country.BIH', alpha3: 'BIH'},
      {name: 'country.BWA', alpha3: 'BWA'},
      {name: 'country.BVT', alpha3: 'BVT'},
      {name: 'country.BRA', alpha3: 'BRA'},
      {name: 'country.IOT', alpha3: 'IOT'},
      {name: 'country.VGB', alpha3: 'VGB'},
      {name: 'country.BRN', alpha3: 'BRN'},
      {name: 'country.BGR', alpha3: 'BGR'},
      {name: 'country.BFA', alpha3: 'BFA'},
      {name: 'country.BDI', alpha3: 'BDI'},
      {name: 'country.CHL', alpha3: 'CHL'},
      {name: 'country.CYP', alpha3: 'CYP'},
      {name: 'country.COM', alpha3: 'COM'},
      {name: 'country.COK', alpha3: 'COK'},
      {name: 'country.CRI', alpha3: 'CRI'},
      {name: 'country.CUW', alpha3: 'CUW'},
      {name: 'country.TCD', alpha3: 'TCD'},
      {name: 'country.CZE', alpha3: 'CZE'},
      {name: 'country.DNK', alpha3: 'DNK'},
      {name: 'country.ZAF', alpha3: 'ZAF'},
      {name: 'country.SGS', alpha3: 'SGS'},
      {name: 'country.SSD', alpha3: 'SSD'},
      {name: 'country.KOR', alpha3: 'KOR'},
      {name: 'country.DMA', alpha3: 'DMA'},
      {name: 'country.DOM', alpha3: 'DOM'},
      {name: 'country.DJI', alpha3: 'DJI'},
      {name: 'country.ECU', alpha3: 'ECU'},
      {name: 'country.GNQ', alpha3: 'GNQ'},
      {name: 'country.ARE', alpha3: 'ARE'},
      {name: 'country.GBR', alpha3: 'GBR'},
      {name: 'country.EGY', alpha3: 'EGY'},
      {name: 'country.CIV', alpha3: 'CIV'},
      {name: 'country.SLV', alpha3: 'SLV'},
      {name: 'country.ERI', alpha3: 'ERI'},
      {name: 'country.MNP', alpha3: 'MNP'},
      {name: 'country.PRK', alpha3: 'PRK'},
      {name: 'country.EST', alpha3: 'EST'},
      {name: 'country.ETH', alpha3: 'ETH'},
      {name: 'country.FLK', alpha3: 'FLK'},
      {name: 'country.BLR', alpha3: 'BLR'},
      {name: 'country.FRO', alpha3: 'FRO'},
      {name: 'country.FJI', alpha3: 'FJI'},
      {name: 'country.FIN', alpha3: 'FIN'},
      {name: 'country.ATF', alpha3: 'ATF'},
      {name: 'country.GUF', alpha3: 'GUF'},
      {name: 'country.FRA', alpha3: 'FRA'},
      {name: 'country.PYF', alpha3: 'PYF'},
      {name: 'country.PHL', alpha3: 'PHL'},
      {name: 'country.GAB', alpha3: 'GAB'},
      {name: 'country.GMB', alpha3: 'GMB'},
      {name: 'country.GHA', alpha3: 'GHA'},
      {name: 'country.GIB', alpha3: 'GIB'},
      {name: 'country.GRC', alpha3: 'GRC'},
      {name: 'country.GRD', alpha3: 'GRD'},
      {name: 'country.GRL', alpha3: 'GRL'},
      {name: 'country.GEO', alpha3: 'GEO'},
      {name: 'country.GLP', alpha3: 'GLP'},
      {name: 'country.GUM', alpha3: 'GUM'},
      {name: 'country.GTM', alpha3: 'GTM'},
      {name: 'country.GGY', alpha3: 'GGY'},
      {name: 'country.GIN', alpha3: 'GIN'},
      {name: 'country.GUY', alpha3: 'GUY'},
      {name: 'country.HTI', alpha3: 'HTI'},
      {name: 'country.HMD', alpha3: 'HMD'},
      {name: 'country.NLD', alpha3: 'NLD'},
      {name: 'country.HND', alpha3: 'HND'},
      {name: 'country.HKG', alpha3: 'HKG'},
      {name: 'country.HRV', alpha3: 'HRV'},
      {name: 'country.IND', alpha3: 'IND'},
      {name: 'country.IDN', alpha3: 'IDN'},
      {name: 'country.IRQ', alpha3: 'IRQ'},
      {name: 'country.IRN', alpha3: 'IRN'},
      {name: 'country.IRL', alpha3: 'IRL'},
      {name: 'country.ISL', alpha3: 'ISL'},
      {name: 'country.ISR', alpha3: 'ISR'},
      {name: 'country.JAM', alpha3: 'JAM'},
      {name: 'country.JPN', alpha3: 'JPN'},
      {name: 'country.YEM', alpha3: 'YEM'},
      {name: 'country.JEY', alpha3: 'JEY'},
      {name: 'country.JOR', alpha3: 'JOR'},
      {name: 'country.CYM', alpha3: 'CYM'},
      {name: 'country.KHM', alpha3: 'KHM'},
      {name: 'country.CMR', alpha3: 'CMR'},
      {name: 'country.CAN', alpha3: 'CAN'},
      {name: 'country.CXR', alpha3: 'CXR'},
      {name: 'country.BES', alpha3: 'BES'},
      {name: 'country.QAT', alpha3: 'QAT'},
      {name: 'country.KAZ', alpha3: 'KAZ'},
      {name: 'country.TLS', alpha3: 'TLS'},
      {name: 'country.KEN', alpha3: 'KEN'},
      {name: 'country.CHN', alpha3: 'CHN'},
      {name: 'country.KGZ', alpha3: 'KGZ'},
      {name: 'country.KIR', alpha3: 'KIR'},
      {name: 'country.CCK', alpha3: 'CCK'},
      {name: 'country.COL', alpha3: 'COL'},
      {name: 'country.COD', alpha3: 'COD'},
      {name: 'country.COG', alpha3: 'COG'},
      {name: 'country.XKX', alpha3: 'XKX'},
      {name: 'country.CAF', alpha3: 'CAF'},
      {name: 'country.CUB', alpha3: 'CUB'},
      {name: 'country.KWT', alpha3: 'KWT'},
      {name: 'country.LAO', alpha3: 'LAO'},
      {name: 'country.POL', alpha3: 'POL'},
      {name: 'country.LSO', alpha3: 'LSO'},
      {name: 'country.LVA', alpha3: 'LVA'},
      {name: 'country.LBN', alpha3: 'LBN'},
      {name: 'country.LBR', alpha3: 'LBR'},
      {name: 'country.LBY', alpha3: 'LBY'},
      {name: 'country.LIE', alpha3: 'LIE'},
      {name: 'country.LTU', alpha3: 'LTU'},
      {name: 'country.LUX', alpha3: 'LUX'},
      {name: 'country.MKD', alpha3: 'MKD'},
      {name: 'country.MDG', alpha3: 'MDG'},
      {name: 'country.HUN', alpha3: 'HUN'},
      {name: 'country.MAC', alpha3: 'MAC'},
      {name: 'country.MYS', alpha3: 'MYS'},
      {name: 'country.MWI', alpha3: 'MWI'},
      {name: 'country.MDV', alpha3: 'MDV'},
      {name: 'country.MLI', alpha3: 'MLI'},
      {name: 'country.MLT', alpha3: 'MLT'},
      {name: 'country.IMN', alpha3: 'IMN'},
      {name: 'country.MAR', alpha3: 'MAR'},
      {name: 'country.MHL', alpha3: 'MHL'},
      {name: 'country.MTQ', alpha3: 'MTQ'},
      {name: 'country.MRT', alpha3: 'MRT'},
      {name: 'country.MUS', alpha3: 'MUS'},
      {name: 'country.MYT', alpha3: 'MYT'},
      {name: 'country.MEX', alpha3: 'MEX'},
      {name: 'country.MMR', alpha3: 'MMR'},
      {name: 'country.FSM', alpha3: 'FSM'},
      {name: 'country.MDA', alpha3: 'MDA'},
      {name: 'country.MCO', alpha3: 'MCO'},
      {name: 'country.MNG', alpha3: 'MNG'},
      {name: 'country.MNE', alpha3: 'MNE'},
      {name: 'country.MSR', alpha3: 'MSR'},
      {name: 'country.MOZ', alpha3: 'MOZ'},
      {name: 'country.NAM', alpha3: 'NAM'},
      {name: 'country.NRU', alpha3: 'NRU'},
      {name: 'country.DEU', alpha3: 'DEU'},
      {name: 'country.NPL', alpha3: 'NPL'},
      {name: 'country.NIC', alpha3: 'NIC'},
      {name: 'country.NER', alpha3: 'NER'},
      {name: 'country.NGA', alpha3: 'NGA'},
      {name: 'country.NIU', alpha3: 'NIU'},
      {name: 'country.NFK', alpha3: 'NFK'},
      {name: 'country.NOR', alpha3: 'NOR'},
      {name: 'country.ESH', alpha3: 'ESH'},
      {name: 'country.ITA', alpha3: 'ITA'},
      {name: 'country.OMN', alpha3: 'OMN'},
      {name: 'country.RUS', alpha3: 'RUS'},
      {name: 'country.ARM', alpha3: 'ARM'},
      {name: 'country.PAK', alpha3: 'PAK'},
      {name: 'country.PLW', alpha3: 'PLW'},
      {name: 'country.PSE', alpha3: 'PSE'},
      {name: 'country.PAN', alpha3: 'PAN'},
      {name: 'country.PNG', alpha3: 'PNG'},
      {name: 'country.PRY', alpha3: 'PRY'},
      {name: 'country.PER', alpha3: 'PER'},
      {name: 'country.PCN', alpha3: 'PCN'},
      {name: 'country.PRT', alpha3: 'PRT'},
      {name: 'country.PRI', alpha3: 'PRI'},
      {name: 'country.REU', alpha3: 'REU'},
      {name: 'country.ROU', alpha3: 'ROU'},
      {name: 'country.RWA', alpha3: 'RWA'},
      {name: 'country.BLM', alpha3: 'BLM'},
      {name: 'country.KNA', alpha3: 'KNA'},
      {name: 'country.LCA', alpha3: 'LCA'},
      {name: 'country.MAF', alpha3: 'MAF'},
      {name: 'country.SPM', alpha3: 'SPM'},
      {name: 'country.VCT', alpha3: 'VCT'},
      {name: 'country.SLB', alpha3: 'SLB'},
      {name: 'country.SMR', alpha3: 'SMR'},
      {name: 'country.STP', alpha3: 'STP'},
      {name: 'country.SYC', alpha3: 'SYC'},
      {name: 'country.SLE', alpha3: 'SLE'},
      {name: 'country.SXM', alpha3: 'SXM'},
      {name: 'country.ESP', alpha3: 'ESP'},
      {name: 'country.SJM', alpha3: 'SJM'},
      {name: 'country.LKA', alpha3: 'LKA'},
      {name: 'country.SUR', alpha3: 'SUR'},
      {name: 'country.CHE', alpha3: 'CHE'},
      {name: 'country.SWE', alpha3: 'SWE'},
      {name: 'country.WSM', alpha3: 'WSM'},
      {name: 'country.SAU', alpha3: 'SAU'},
      {name: 'country.SEN', alpha3: 'SEN'},
      {name: 'country.SHN', alpha3: 'SHN'},
      {name: 'country.SRB', alpha3: 'SRB'},
      {name: 'country.SCG', alpha3: 'SCG'},
      {name: 'country.SGP', alpha3: 'SGP'},
      {name: 'country.SYR', alpha3: 'SYR'},
      {name: 'country.SVK', alpha3: 'SVK'},
      {name: 'country.SVN', alpha3: 'SVN'},
      {name: 'country.SOM', alpha3: 'SOM'},
      {name: 'country.SDN', alpha3: 'SDN'},
      {name: 'country.SWZ', alpha3: 'SWZ'},
      {name: 'country.TJK', alpha3: 'TJK'},
      {name: 'country.TWN', alpha3: 'TWN'},
      {name: 'country.TZA', alpha3: 'TZA'},
      {name: 'country.THA', alpha3: 'THA'},
      {name: 'country.TGO', alpha3: 'TGO'},
      {name: 'country.TKL', alpha3: 'TKL'},
      {name: 'country.TON', alpha3: 'TON'},
      {name: 'country.TUR', alpha3: 'TUR'},
      {name: 'country.TTO', alpha3: 'TTO'},
      {name: 'country.TUN', alpha3: 'TUN'},
      {name: 'country.TCA', alpha3: 'TCA'},
      {name: 'country.TUV', alpha3: 'TUV'},
      {name: 'country.TKM', alpha3: 'TKM'},
      {name: 'country.UGA', alpha3: 'UGA'},
      {name: 'country.NCL', alpha3: 'NCL'},
      {name: 'country.NZL', alpha3: 'NZL'},
      {name: 'country.UKR', alpha3: 'UKR'},
      {name: 'country.URY', alpha3: 'URY'},
      {name: 'country.UZB', alpha3: 'UZB'},
      {name: 'country.VUT', alpha3: 'VUT'},
      {name: 'country.VAT', alpha3: 'VAT'},
      {name: 'country.VEN', alpha3: 'VEN'},
      {name: 'country.VNM', alpha3: 'VNM'},
      {name: 'country.WLF', alpha3: 'WLF'},
      {name: 'country.ZMB', alpha3: 'ZMB'},
      {name: 'country.ZWE', alpha3: 'ZWE'},
      {name: 'country.CPV', alpha3: 'CPV'}
    ]);
}());
